<template>
  <div>
    <div class="candidate-listing-container">
      <div>
        <div class="tabs-container">
          <a-tabs
            v-model:activeKey="activeKey"
            :animated="false"
            class="listing-tabs"
            @change="onChangeTab(activeKey)"
          >
            <a-tab-pane
              key="candidates"
              tab="Candidates"
              class="tab-pane-container"
            >
              <a-divider />
              <div class="tab-container">
                <div class="search-container">
                  <a-input-search
                    class="header-search-box"
                    placeholder="Search"
                    @search="searchCandidate"
                  />
                  <a-button
                    style="
                      height: 44px;
                      border-radius: 13px;
                      background: white;
                      border: none;
                      font-family: 'Poppins Medium';
                    "
                    type="ghost"
                    @click="showFilters"
                  >
                    <FilterOutlined style="font-size: 18px" />
                    Filters
                  </a-button>
                </div>
                <div class="candidate-listing-table">
                  <a-table
                    :columns="listedCandidateColumns"
                    :data-source="candidatesData"
                    :row-selection="rowSelection"
                    :pagination="false"
                    :rowKey="(record) => record.candidateName.id"
                  >
                    <template #candidateName="{ text }">
                      <div style="display: flex">
                        <div class="candidate-image-icon" v-if="text.imageUrl">
                          <img
                            v-if="text"
                            src="{{text}}"
                            alt="Candidate image"
                          />
                        </div>
                        <div class="candidate-image-icon" v-else>
                          <a-avatar>
                            <template #icon><UserOutlined /></template>
                          </a-avatar>
                          &nbsp;
                        </div>
                        <div style="padding-top: 5px">
                          <span @click="fetchCandidateProfile(text.id)">
                            {{ text.name }}
                          </span>
                          <div>
                            <span>
                              <a
                                class="candidate-listing-resume-id"
                                v-if="text.resumeId"
                                >{{ text.resumeId }}</a
                              >
                            </span>
                            <a-button
                              type="link"
                              class="candidate-listing-taxonomy-btn"
                              @click="showTaxonomy(text.id)"
                            >
                              <IdcardOutlined
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                            <a-button
                              type="link"
                              v-if="
                                text.socialAccounts.linkedIn &&
                                Object.keys(text.socialAccounts.linkedIn)
                                  .length !== 0
                              "
                              style=""
                              class="candidate-listing-social-media-btn"
                              href="text.socialAccounts.linkedIn.link"
                            >
                              <LinkedinFilled
                                circle
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #rating="{ text }">
                      <a-rate
                        :value="parseFloat(text / 2)"
                        disabled
                        allowHalf
                        count="4"
                      />
                    </template>
                    <template #ctc="{ text }">
                      <div class="candidate-text-color-light">
                        {{ text }}
                      </div>
                    </template>
                    <template #city="{ text }">
                      <div class="candidate-text-color-light">
                        {{ text }}
                      </div>
                    </template>
                    <template #taxonomy="{ text }">
                      <span v-if="text">{{ text }}</span>
                      <span v-else> - </span>
                    </template>
                    <template #cic="{ text }">
                      <div v-for="item in text" :key="item">
                        {{ item }}
                      </div>
                    </template>
                  </a-table>
                </div>
                <div class="view-more-btn" v-if="listedCandidatesViewMore">
                  <a-button type="link" @click="fetchMoreCandidates">
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
            <!-- Candidate Group Listing -->
            <a-tab-pane
              key="candidate-groups"
              tab="Candidate Groups"
              class="tab-pane-container"
            >
              <a-divider />
              <div class="tab-container">
                <div class="search-container">
                  <a-input-search
                    class="header-search-box"
                    placeholder="Search"
                    @search="fetchCandidateGroups"
                  />
                  <a-button
                    style="
                      height: 44px;
                      border-radius: 13px;
                      background: white;
                      border: none;
                      font-family: 'Poppins Medium';
                    "
                    type="ghost"
                    @click="showCandidateGroupFilters"
                  >
                    <FilterOutlined style="font-size: 18px" />
                    Filters
                  </a-button>
                </div>

                <div class="candidate-group-listing-table">
                  <div class="candidate-groups-radio">
                    <div class="candidates-all-groups">
                      <TeamOutlined />All Groups
                    </div>
                    <div class="candidates-all-groups">
                      <TeamOutlined />Saved Groups
                    </div>
                  </div>

                  <!-- Candidates Group Table  -->
                  <a-table
                    :columns="candidateGroupListingColumns"
                    :data-source="candidateGroupListingData"
                    :row-selection="treeRowSelection"
                    :pagination="false"
                    :rowKey="(record) => record.id"
                  >
                  </a-table>
                </div>
                <div class="view-more-btn" v-if="candidateGroupViewMore">
                  <a-button type="link" @click="fetchMoreCandidateGroups">
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
            <!-- Recommended Candidates Listing -->
            <a-tab-pane
              key="recommended-candidates"
              tab="Recommended Candidates"
              class="tab-pane-container"
            >
              <a-divider />
              <div class="tab-container">
                <div class="search-container">
                  <a-input-search
                    class="header-search-box"
                    placeholder="Search"
                    @search="searchRecommendedCandidates"
                  />
                  <a-button
                    style="
                      height: 44px;
                      border-radius: 13px;
                      background: white;
                      border: none;
                      font-family: 'Poppins Medium';
                    "
                    type="ghost"
                    @click="recommendedCandidatesShowFilters"
                  >
                    <FilterOutlined style="font-size: 18px" />
                    Filters
                  </a-button>
                </div>

                <div class="candidate-listing-table">
                  <a-table
                    :columns="listedCandidateColumns"
                    :data-source="recommendedCandidatesData"
                    :row-selection="rowSelection"
                    :pagination="false"
                    :rowKey="(record) => record.candidateName.id"
                  >
                    <template #candidateName="{ text }">
                      <div style="display: flex">
                        <div class="candidate-image-icon" v-if="text.imageUrl">
                          <img
                            v-if="text"
                            src="{{text}}"
                            alt="Candidate image"
                          />
                        </div>
                        <div class="candidate-image-icon" v-else>
                          <a-avatar>
                            <template #icon><UserOutlined /></template>
                          </a-avatar>
                          &nbsp;
                        </div>
                        <div style="padding-top: 5px">
                          <span @click="fetchCandidateProfile(text.id)">
                            {{ text.name }}
                          </span>
                          <div>
                            <span>
                              <a
                                class="candidate-listing-resume-id"
                                v-if="text.resumeId"
                                >{{ text.resumeId }}</a
                              >
                            </span>
                            <a-button
                              type="link"
                              class="candidate-listing-taxonomy-btn"
                              @click="showTaxonomy(text.id)"
                            >
                              <IdcardOutlined
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                            <a-button
                              type="link"
                              v-if="
                                text.socialAccounts.linkedIn &&
                                Object.keys(text.socialAccounts.linkedIn)
                                  .length !== 0
                              "
                              style=""
                              class="candidate-listing-social-media-btn"
                              href="text.socialAccounts.linkedIn.link"
                            >
                              <LinkedinFilled
                                circle
                                class="candidate-profile-social-media-icon"
                              />
                            </a-button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #rating="{ text }">
                      <a-rate
                        :value="parseFloat(text / 2)"
                        disabled
                        allowHalf
                        count="4"
                      />
                    </template>
                    <template #taxonomy="{ text }">
                      <span v-if="text">{{ text }}</span>
                      <span v-else> - </span>
                    </template>
                    <template #cic="{ text }">
                      <div v-for="item in text" :key="item">
                        {{ item }}
                      </div>
                    </template>
                  </a-table>
                </div>
                <div class="view-more-btn">
                  <a-button type="link" @click="fetchMoreRecommendedCandidates">
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
          <!-- Candidate Filter Modal -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="filterModalVisibility"
            :closable="false"
            footer=""
            centered
            width="1200px"
          >
            <div>
              <div style="width=80%">
                <candidateFilter
                  @closeFilter="filterModalCancel"
                  @filterModalOk="filterModalOk"
                />
              </div>
            </div>
            <template #footer>
              <a-button style="margin: 0.5rem 0"> </a-button>
            </template>
          </a-modal>
          <!-- Candidate Taxonomy Widget Modal -->
          <a-modal
            class="filter-modal"
            title=""
            width="500px"
            v-model:visible="taxonomyModalVisibility"
            :closable="false"
            centered
            footer=""
            destroyOnClose
          >
            <TaxonomyWidget :id="taxonomy_id" />
          </a-modal>
          <!-- Candiddate Group Filter  -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="candidateGroupFilterModalVisibility"
            :closable="false"
            centered
            @ok="candidateGroupFilterModalOk"
            width="1200px"
          >
            <div>
              <div style="width=80%">
                <candidateGroupFilter />
              </div>
            </div>
            <template #footer>
              <a-button
                type="link"
                style="
                  color: rgb(76, 81, 85);
                  font-size: $font_size_12;
                  font-family: Poppins;
                  font-weight: 500;
                "
                key="back"
                @click="candidateGroupFilterModalCancel"
                >Cancel</a-button
              >
              <a-button
                key="submit"
                style="
                  background: RGB(228, 129, 106);
                  color: #ffffff;
                  border-radius: 15px;
                  font-size: $font_size_12;
                  font-family: Poppins;
                  font-weight: 500;
                "
                @click="candidateGroupFilterModalOk"
                >Apply Filter</a-button
              >
            </template>
          </a-modal>
          <!-- Recommended Candidate Filter Modal -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="recommendedCandidatesFilterModalVisibility"
            :closable="false"
            footer=""
            centered
            width="1200px"
          >
            <div>
              <div style="width=80%">
                <candidateFilter
                  @closeFilter="recommendedCandidatesFilterModalCancel"
                  @filterModalOk="recommendedCandidatesFilterModalOk"
                />
              </div>
            </div>
            <template #footer>
              <a-button style="margin: 0.5rem 0"> </a-button>
            </template>
          </a-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import candidateFilter from "../../candidate/components/candidateFilter.vue";
import TaxonomyWidget from "../../candidate/components/widget.vue";
import candidateGroupFilter from "../../candidate/components/candidateGroupFilter.vue";

import {
  ref,
  onUnmounted,
  onMounted,
  defineComponent,
  reactive,
  toRef,
  computed,
} from "vue";
import Services from "../../../services/apis";
import {
  FilterOutlined,
  UserOutlined,
  LinkedinFilled,
  PlusCircleOutlined,
  IdcardOutlined,
  TeamOutlined,
} from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

let candidatesData = reactive([]);
let candidateGroupListingData = reactive([]);
let recommendedCandidatesData = reactive([]);
export default defineComponent({
  components: {
    FilterOutlined,
    UserOutlined,
    LinkedinFilled,
    IdcardOutlined,
    PlusCircleOutlined,
    candidateFilter,
    TaxonomyWidget,
    candidateGroupFilter,
    TeamOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {
      params: { id },
    } = useRoute();

    let listedCandidatesCount = ref();
    let listedCandidatesViewMore = ref(false);
    let candidateGroupsCount = ref();
    let candidateGroupViewMore = ref(false);
    let recommendedCandidatesCount = ref();
    let recommendedCandidatesViewMore = ref(false);
    const vacancyId = computed(
      () => store.getters["workbenchStore/getVacancyId"]
    ).value;
    let taxonomy_id = ref("");
    // Candidate Listing Column Definition
    let listedCandidateColumns = reactive([
      {
        title: "Candidate",
        default: true,
        dataIndex: "candidateName",
        key: "candidateName",
        align: "left",
        slots: { customRender: "candidateName" },
        width: 175,
      },
      {
        title: "Rating",
        default: true,
        dataIndex: "rating",
        key: "rating",
        align: "left",
        width: 120,
        slots: { customRender: "rating" },
      },
      {
        width: 125,
        title: "Compensation",
        default: true,
        dataIndex: "ctc",
        key: "ctc",
        ellipsis: true,
        slots: { customRender: "ctc" },
        align: "center",
      },
      {
        title: "City",
        default: true,
        dataIndex: "city",
        key: "city",
        slots: { customRender: "city" },
        align: "center",
      },
      {
        title: "T.W.Exp",
        default: true,
        dataIndex: "totalWorkExp",
        key: "totalWorkExp",
        slots: { customRender: "totalWorkExp" },
      },
      {
        title: "Work Exp.",
        default: true,
        dataIndex: "workExperience",
        key: "workExperience",
        slots: { customRender: "workExperience" },
        width: 125,
        align: "center",
      },
      {
        title: "Taxonomy",
        default: true,
        dataIndex: "taxonomy",
        key: "taxonomy",
        slots: { customRender: "taxonomy" },
        align: "center",
      },
      {
        title: "Call",
        default: true,
        dataIndex: "call",
        key: "call",
        slots: { customRender: "call" },
        align: "center",
      },

      {
        title: "CIC Key",
        default: true,
        dataIndex: "cic",
        key: "cic",
        slots: { customRender: "cic" },
      },

      {
        title: "Docs.",
        default: true,
        dataIndex: "documentsCount",
        key: "documentsCount",
        slots: { customRender: "documentsCount" },
      },
    ]);

    // Candidate Group Listing Column Definition
    const candidateGroupListingColumns = [
      {
        title: "Groups",
        dataIndex: "name",
        key: "name",
        slots: { customRender: "name" },
      },
      {
        title: "No. of Candidates",
        dataIndex: "noOfCustomers",
        key: "noOfCustomers",
        slots: { customRender: "noOfCustomers" },
        align: "center",
      },
    ];

    let ratingValue = ref();
    // Candidate Listing Modals Variables
    const filterModalVisibility = ref(false);
    const taxonomyModalVisibility = ref(false);

    // Candidate Group Listing Modals Variables
    const candidateGroupFilterModalVisibility = ref(false);

    // Recommmended Candidate Listing Modals Variables
    const recommendedCandidatesFilterModalVisibility = ref(false);

    // Candidate Listing Modal Actions
    const showFilters = () => {
      filterModalVisibility.value = true;
    };
    const showTaxonomy = (id) => {
      taxonomy_id.value = id;
      taxonomyModalVisibility.value = true;
    };
    const filterModalOk = (payload, value) => {
      fetchCandidates(payload);
      filterModalVisibility.value = value;
    };
    const filterModalCancel = (value) => {
      filterModalVisibility.value = value;
    };
    // Candidate Group Listing Modal Actions
    const showCandidateGroupFilters = () => {
      candidateGroupFilterModalVisibility.value = true;
    };
    const candidateGroupFilterModalOk = (payload, value) => {
      candidateGroupFilterModalVisibility.value = false;
    };
    const candidateGroupFilterModalCancel = () => {
      candidateGroupFilterModalVisibility.value = false;
    };
    // Recommended Candidate Listing Modal Actions
    const recommendedCandidatesShowFilters = () => {
      recommendedCandidatesFilterModalVisibility.value = true;
    };
    const recommendedCandidatesFilterModalOk = (payload, value) => {
      fetchCandidates(payload);
      recommendedCandidatesFilterModalVisibility.value = value;
    };
    const recommendedCandidatesFilterModalCancel = (value) => {
      recommendedCandidatesFilterModalVisibility.value = value;
    };
    let pageCount = 0;

    //Row Selection acllback function for Listed and Recommended Candidates
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        store.dispatch("workbenchStore/setSelectedCandidates", selectedRowKeys);
      },
    };

    // Candidate Group Row Selection calllback function
    const treeRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };

    // Candidate Listing Fetchmore, search and listing functions
    const fetchMoreCandidates = () => {
      pageCount++;
      fetchCandidates();
    };

    const searchCandidate = (value) => {
      pageCount = 0;
      fetchCandidates(value);
    };

    const fetchCandidateProfile = (id) => {
      router.push(`/candidates/${id}/detail`);
    };

    const fetchCandidates = async (value) => {
      let listData = [];
      let q = "";
      let payload = {
        q: "",
        pageNo: pageCount,
        mode: "LISTING",
      };
      if (pageCount > 0) {
        listData = candidatesData;
      }
      if (typeof value === "string") {
        candidatesData.splice(0);
        payload = { q: value, pageNo: pageCount, mode: "LISTING" };
      } else if (typeof value === "object") {
        candidatesData.splice(0);
        payload = {
          ...value,
          pageNo: pageCount,
          mode: "LISTING",
        };
      }

      await Services.candidatesListing(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data[0].count &&
            data[0].candidates.map((x) => {
              listData.push({
                candidateName: {
                  imageUrl: x.imageUrl,
                  name: x.name,
                  resumeId: x.resumeId,
                  experience: x.experience,
                  socialAccounts: x.socialAccounts,
                  education: x.education,
                  subPracticeArea: x.subPracticeArea,
                  practiceArea: x.practiceArea,
                  designation: "",
                  id: x.id,
                },
                rating: ref(x.rating),
                city: x.city ? x.city : "-",
                notesCount: x.notesCount ? x.notesCount : "-",
                totalWorkExp: x.totalWorkExp ? x.totalWorkExp : "-",
                workExperience: x.workExperience ? x.workExperience : "-",
                documentsCount: x.documentsCount ? x.documentsCount : "-",
                ctc: x.ctc ? x.ctc : "-",
                cic: x.cic ? x.cic.map((x) => x.name) : "-",
                call: x.call ? x.call : "-",
                status: x.status,
              });
            });
          listedCandidatesCount.value = data[0].count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Object.assign(candidatesData, listData);
      listedCandidatesViewMore.value =
        candidatesData.length < listedCandidatesCount.value;
    };

    // Candidate Group Listing Fetchmore, search and listing functions
    const fetchCandidateGroups = async (value) => {
      let groupListData = [];
      let q = "";
      let payload = {
        q: "",
        pageNo: pageCount,
      };
      if (pageCount > 0) {
        groupListData = candidateGroupListingData;
      }
      if (typeof value === "string") {
        candidateGroupListingData.splice(0);
        payload = { q: value, pageNo: pageCount, mode: "LISTING" };
      } else if (typeof value === "object") {
        candidateGroupListingData.splice(0);
        payload = {
          ...value,
          pageNo: pageCount,
        };
      }

      await Services.getCandidateGroup(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.data.map((x) => {
              groupListData.push({
                id: x.id,
                name: x.name,
                noOfCustomers: x.noOfCustomers,
                children: x.subGroups.map((x) => {
                  let tempObject = {
                    id: x.id,
                    name: x.name,
                    noOfCustomers: x.noOfCustomers,
                  };
                  return tempObject;
                }),
              });
            });
          candidateGroupsCount.value = data.count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Object.assign(candidateGroupListingData, groupListData);
      candidateGroupViewMore.value =
        candidateGroupListingData.length < candidateGroupsCount.value;
    };

    const fetchMoreCandidateGroups = () => {
      pageCount++;
      fetchCandidateGroups();
    };

    const searchCandidateGroups = (value) => {
      pageCount = 0;
      fetchCandidateGroups(value);
    };

    // Recommended Candidate Listing Fetchmore, search and listing functions

    const fetchRecommendedCandidates = async (value) => {
      let listData = [];
      let payload = {
        pageNo: pageCount,
        mode: "LISTING",
        status: "",
        id: id,
      };
      if (pageCount > 0) {
        listData = recommendedCandidatesData;
      }
      if (typeof value === "string") {
        recommendedCandidatesData.splice(0);
        payload = { q: value, pageNo: pageCount, mode: "LISTING" };
      } else if (typeof value === "object") {
        recommendedCandidatesData.splice(0);
        payload = {
          ...value,
          pageNo: pageCount,
          mode: "LISTING",
        };
      }

      await Services.RecommendedCandidatesforVacancy(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.count &&
            data.candidates.map((x) => {
              listData.push({
                candidateName: {
                  imageUrl: x.imageUrl,
                  name: x.name,
                  resumeId: x.resumeId,
                  experience: x.experience,
                  socialAccounts: x.socialAccounts,
                  education: x.education,
                  subPracticeArea: x.subPracticeArea,
                  practiceArea: x.practiceArea,
                  designation: "",
                  id: x.id,
                },
                rating: ref(x.rating),
                city: x.city ? x.city : "-",
                notesCount: x.notesCount ? x.notesCount : "-",
                totalWorkExp: x.totalWorkExp ? x.totalWorkExp : "-",
                workExperience: x.workExperience ? x.workExperience : "-",
                documentsCount: x.documentsCount ? x.documentsCount : "-",
                ctc: x.ctc ? x.ctc : "-",
                cic: x.cic ? x.cic.map((x) => x.name) : "-",
                call: x.call ? x.call : "-",
                status: x.status,
              });
            });
          recommendedCandidatesCount.value = data[0].count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Object.assign(recommendedCandidatesData, listData);
      recommendedCandidatesViewMore.value =
        recommendedCandidatesData.length < recommendedCandidatesCount.value;
    };

    const fetchMoreRecommendedCandidates = () => {
      pageCount++;
      fetchRecommendedCandidates();
    };

    const searchRecommendedCandidates = (value) => {
      pageCount = 0;
      fetchRecommendedCandidates(value);
    };

    const onChangeTab = (tabKey) => {
      if (tabKey === "candidates") {
        if (candidatesData.length === 0) {
          fetchCandidates();
        }
      }
      if (tabKey === "candidate-groups") {
        if (candidateGroupListingData.length === 0) {
          fetchCandidateGroups();
        }
      }
      if (tabKey === "recommended-candidates") {
        if (recommendedCandidatesData.length === 0) {
          fetchRecommendedCandidates();
        }
      }
    };

    onMounted(() => {
      fetchCandidates();
    });
    onUnmounted(() => {
      candidatesData.splice(0);
    });
    return {
      ratingValue,
      showFilters,
      showTaxonomy,
      taxonomy_id,
      onUnmounted,
      filterModalOk,
      filterModalCancel,
      filterModalVisibility,
      taxonomyModalVisibility,
      pageCount,
      listedCandidatesViewMore,
      listedCandidatesCount,
      candidatesData,
      fetchCandidates,
      fetchMoreCandidates,
      searchCandidate,
      fetchCandidateProfile,
      tabKey: ref("candidates"),
      listedCandidateColumns,
      rowSelection,
      onChangeTab,
      // Candidate Group
      candidateGroupListingColumns,
      candidateGroupListingData,
      candidateGroupFilterModalVisibility,
      showCandidateGroupFilters,
      candidateGroupFilterModalOk,
      candidateGroupFilterModalCancel,
      treeRowSelection,
      fetchCandidateGroups,
      fetchMoreCandidateGroups,
      searchCandidateGroups,
      candidateGroupViewMore,
      // Recommended Candidates
      recommendedCandidatesData,
      recommendedCandidatesFilterModalVisibility,
      recommendedCandidatesShowFilters,
      recommendedCandidatesFilterModalOk,
      recommendedCandidatesFilterModalCancel,
      recommendedCandidatesCount,
      recommendedCandidatesViewMore,
      fetchMoreRecommendedCandidates,
      searchRecommendedCandidates,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/attachCandidateListing.scss";
</style>
