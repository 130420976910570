<template>
  <div>
    <div class="vacancy-profile-main-wrapper">
      <div class="left-section">
        <div class="vacancy-profile-first-section">
          <div class="vacancy-profile-first-section-avatar">
            <a-avatar :size="100">
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>

          <div class="vacancy-profile-vacancy-title-section">
            <div class="vacancy-profile-title-with-status">
              <div class="vacancy-profile-vacancy-title">
                {{ vacancyData.title }}
              </div>
              <div>
                <span
                  class="vacancy-profile-status-tag"
                  :style="[
                    vacancyData.status === 'ACTIVE'
                      ? { background: 'rgba(111, 207, 151, 0.4)' }
                      : { background: 'rgba(76, 81, 85, 0.5)' },
                  ]"
                >
                  {{ vacancyData.status }}
                </span>
              </div>
            </div>

            <div class="vacancy-profile-vacancy-level">
              Level:{{ vacancyData.level }}
            </div>
            <div class="vacancy-profile-vacancy-level">
              {{ vacancyData.experienceFrom }}-{{ vacancyData.experienceTo }}
            </div>

            <div class="vacancy-profile-vacancy-type-section">
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Vac.Type</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.vacancyType }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Salary</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.salaryFrom }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Value</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.value }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Dis.Start Date</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.startDate }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Dis.End Date</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.endDate }}
                </div>
              </div>
            </div>
          </div>

          <div class="vacancy-profile-company-section">
            <div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Company</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.account.name }}
                </div>
              </div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Client Contact</div>

                <div
                  class="vacancy-type-responses"
                  v-for="(client, index) in vacancyData.clientPersons"
                  :key="index"
                >
                  {{ client }}
                </div>
              </div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Status</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.vacancyStatus }}
                </div>
              </div>
              <div class="vacancy-profile-company-section-sub-section">
                <span class="vacancy-type-titles" style="margin-right: 10px"
                  >Start Date</span
                >
                <span class="vacancy-type-responses">{{
                  vacancyData.startDate
                }}</span>
              </div>
            </div>
            <div>
              <a style="color: #000000" href="/vacancy/vacancy-list">
                <CloseOutlined class="vacancy-profile-cross-icon" />
              </a>
            </div>
          </div>
        </div>

        <div class="vacancy-profile-attach-candidate-section">
          <CandidateListing />
        </div>
      </div>
      <div class="right-section">
        <ActionBar />
      </div>
    </div>
  </div>
</template>
<script>
import ActionBar from "./rightSideBar.vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { UserOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import Services from "@/services/apis";
import Utils from "@/utils";
import { useStore } from "vuex";
import CandidateListing from "./attachCandidateListing.vue"

let vacancyData = reactive({
  title: "-",
  level: "-",
  vacancyId: "-",
  status: "-",
  experienceFrom: "-",
  experienceTo: "-",
  account: {
    id: "-",
    name: "-",
    companyId: "-",
    logo: "-",
  },
  clientPersons: [
    {
      id: "-",
      name: "-",
    },
  ],
  vacancyStatus: "-",
  startDate: "-",
  endDate: "-",
  vacancyType: "-",
  city: "-",
  originatedBy: "-",
  fee: 0,
  verticals: [
    {
      id: "-",
      name: "-",
    },
  ],
  practiceAreas: [
    {
      id: "-",
      name: "-",
    },
  ],
  subPracticeAreas: [
    {
      id: "-",
      name: "-",
    },
  ],
  salaryFrom: "-",
  salaryTo: "-",
  value: "-",
  cic: [
    {
      user: {
        id: "-",
        name: "-",
      },
      isLead: true,
      revShare: 0,
    },
  ],
  teams: [
    {
      team: {
        id: "-",
        name: "-",
      },
      subTeam: {
        id: "-",
        name: "-",
      },
      revShare: 0,
    },
  ],
  documents: [
    {
      id: "-",
      name: "-",
      type: "-",
      url: "-",
      size: 0,
    },
  ],
});

export default defineComponent({
  components: {
    CandidateListing,
    UserOutlined,
    CloseOutlined,
    ActionBar,
  },

  setup() {
    const store = useStore();
    const {
      params: { id },
    } = useRoute();

    const getVacancyProfileData = (id) => {
      let tempData;
      Services.getVacancyProfile(id).then((response) => {
        const { data } = response;
        if (data) {
          tempData = {
            title: data.title ? data.title : "-",
            level: data.level ? data.level : "-",
            vacancyId: data.vacancyId ? data.vacancyId : "-",
            status: data.status ? data.status : "-",
            experienceFrom: data.experienceFrom ? data.experienceFrom : "-",
            experienceTo: data.experienceTo ? data.experienceTo : "-",
            account: {
              id: data.account[0].id ? data.account[0].id : "-",
              name: data.account[0].name ? data.account[0].name : "-",
            },
            clientPersons: data.clientPersons
              ? data.clientPersons.map((x) => x.name)
              : "-",
            vacancyStatus: data.vacancyStatus ? data.vacancyStatus : "-",
            startDate: data.startDate
              ? Utils.displayLongDate(data.startDate)
              : "-",
            endDate: data.endDate ? Utils.displayLongDate(data.endDate) : "-",
            vacancyType: data.vacancyType ? data.vacancyType : "-",
            cities: data.city ? data.city.map((x) => x.city) : "-",
            originatedBy: data.originatedBy ? data.originatedBy : "-",
            fee: 0,
            salaryFrom: data.salaryFrom ? Utils.rounding(data.salaryFrom) : "-",
            salaryTo: data.salaryTo ? data.salaryTo : " ",
            value: data.value ? Utils.rounding(data.value) : "-",
          };
        }
        Object.assign(vacancyData, tempData);
      });
    };

    onMounted(() => {
      getVacancyProfileData(id);
    });

    return {
      vacancyData,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/attachCandidates.scss";
</style>
