<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <span>Filter by</span>
      <a-button type="link">Reset Changes</a-button>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="filter-personal-details">
          <div class="filter-personal-details-form">
            <div class="title">Personal Details</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 33%"
              >
                <a-form-item>
                  <a-input class="form-input" placeholder="Enter Phone Number">
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%; margin-right: 0"
              >
                <a-form-item>
                  <a-input
                    class="form-input"
                    placeholder="Enter Email"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <a-input
                    class="form-input"
                    placeholder="Enter Name"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 6.5rem"
          />
          <div style="flex-basis: 9%">
            <a-tag color="rgba(92,121,249,1)" class="filter-tags" closable
              >Personal</a-tag
            >
            <a-tag color="rgba(92,121,249,1)" class="filter-tags" closable
              >Details</a-tag
            >
            <a-tag color="rgba(92,121,249,1)" class="filter-tags" closable
              >Tags</a-tag
            >
          </div>
        </div>
        <div class="vacancy-details">
          <div class="vacancy-details-form">
            <div class="title">Vacancy</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy ID</span>
                  <a-input class="form-input"> </a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 65%">
                <a-form-item>
                  <span class="input-label">Vacancy Title</span>
                  <a-input class="form-input"></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy Status</span>
                  <a-input class="form-input"> </a-input>
                </a-form-item>
              </div>
              <div style="display: flex; flex-basis: 57%">
                <div class="filter-form-elements" style="flex-basis: 40%">
                  <a-form-item>
                    <span class="input-label">Vacancy Type</span>
                    <a-select
                      class="filter-select"
                      mode="tags"
                      label-in-value
                      v-model:value="vacancyTypeValue"
                      :filter-option="false"
                      :options="vacancy_type_options"
                      @search="fetchVacancyTypeData"
                    ></a-select>
                  </a-form-item>
                </div>
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">From Date</span>
                    <a-date-picker
                      class="filter-date-input"
                      style="padding-top: 0.65rem; margin-right: 1rem"
                    ></a-date-picker>
                  </a-form-item>
                </div>
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">To Date</span>
                    <a-date-picker
                      class="filter-date-input"
                      style="padding-top: 0.65rem; margin-right: 1rem"
                      placeholder="To Date"
                    ></a-date-picker>
                  </a-form-item>
                </div>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 53.5%"
              >
                <a-form-item>
                  <span class="input-label">Lead CIC</span>
                  <a-select
                    class="filter-select"
                    :options="lead_cic_options"
                    v-model:value="leadCICValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 37.5%">
                <a-form-item>
                  <span class="input-label">Originated By</span>
                  <a-select
                    class="filter-select"
                    :options="lead_cic_options"
                    v-model:value="originatebyValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 20.5rem"
          />
        </div>
        
        <div class="lorem-ipsum-section">
          <div class="lorem-ipsum-section-form">
            <div class="title">Lorem Ipsum</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Degree</span>
                  <a-select
                    class="filter-select"
                    :options="education_degrees_options"
                    v-model:value="degreeValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 61%">
                <a-form-item>
                  <span class="input-label">Institute</span>
                  <a-select
                    class="filter-select"
                    :options="university_options"
                    v-model:value="universityValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Resume Type</span>
                  <a-select
                    class="filter-select"
                    :options="resume_options"
                    v-model:value="resumeValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">City</span>
                  <a-select
                    class="filter-select"
                    :options="city_options"
                    v-model:value="cityValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <span class="input-label">Designation</span>
                  <a-select
                    class="filter-select"
                    :options="designation_options"
                    v-model:value="designationValue"
                    style="width: 100%"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 14.5rem"
          />
        </div>
        <div class="workflow-section">
          <div class="workflow-section-form">
            <div class="title">Workflow</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="teamValue"
                    :filter-option="false"
                    :options="team_tags_options"
                    @search="fetchTeamsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Sub Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="subTeamValue"
                    :filter-option="false"
                    :options="sub_team_tags_options"
                    @search="fetchSubTeamsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Procedure Stage</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="procedureStageValue"
                    :filter-option="false"
                    :options="procedure_stage_tags_options"
                    @search="fetchProcedureStageData;"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 22rem"
          />
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import Services from "../../../services/apis";
// import { Form } from "ant-design-vue";

export default defineComponent({
  setup() {
    let vacancy_type_options = ref([]);
    let lead_cic_options = ref([]);
    let sector_tags_options = ref([]);
    let industry_tags_options = ref([]);
    let verticals_tags_options = ref([]);
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let function_tags_options = ref([]);
    let sub_function_tags_options = ref([]);
    let sub_sub_function_tags_options = ref([]);
    let company_types_tags_options = ref([]);
    let education_degrees_options = ref([]);
    let university_options = ref([]);
    let resume_options = ref([]);
    let city_options = ref([]);
    let designation_options = ref([]);
    let team_tags_options = ref([]);
    let sub_team_tags_options = ref([]);
    let procedure_stage_tags_options = ref([]);

    const fetchVacancyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["VACANCY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          vacancy_type_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          verticals_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        mode: "AUTOCOMPLETE",
        types: ["SUB_PRACTICE_AREA"],
      };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["COMPANY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          company_types_tags_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["SUB_TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          sub_team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchProcedureStageData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          procedure_stage_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    onMounted(() => {
      fetchVacancyTypeData();
      fetchVerticalData();
      fetchPracticeAreaData();
      fetchSubPracticeAreaData();
      fetchFunctionData();
      fetchSubFunctionData();
      fetchSubSubFunctionData();
      fetchSectorData();
      fetchIndustryData();
      fetchCompanyTypeData();
      fetchTeamsData();
      fetchSubTeamsData();
      fetchProcedureStageData();

      let user_listing_payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        type: "MEMBER",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          lead_cic_options.value = data.data.map(
            ({ id: value, firstName: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.eduDegreesListing({ types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          education_degrees_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.eduInstituteListing({ types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          university_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.resumeListing({ types: ["RESUME"] })
        .then((response) => {
          const { data } = response;
          resume_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.cityListing()
        .then((response) => {
          const { data } = response;
          city_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.designationListing()
        .then((response) => {
          const { data } = response;
          designation_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });

    return {
      // All select elements value refs
      vacancyTypeValue: ref([]),
      leadCICValue: ref([]),
      originatebyValue: ref([]),
      sectorValue: ref([]),
      industryValue: ref([]),
      verticalsValue: ref([]),
      practiceAreaValue: ref([]),
      subPracticeAreaValue: ref([]),
      functionValue: ref([]),
      subFunctionValue: ref([]),
      subSubFunctionValue: ref([]),
      companyTypeValue: ref([]),
      degreeValue: ref([]),
      universityValue: ref([]),
      resumeValue: ref([]),
      cityValue: ref([]),
      designationValue: ref([]),
      teamValue: ref([]),
      subTeamValue: ref([]),
      procedureStageValue: ref([]),

      // All select items options
      vacancy_type_options,
      lead_cic_options,
      sector_tags_options,
      industry_tags_options,
      verticals_tags_options,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      function_tags_options,
      sub_function_tags_options,
      sub_sub_function_tags_options,
      company_types_tags_options,
      education_degrees_options,
      university_options,
      resume_options,
      city_options,
      designation_options,
      team_tags_options,
      sub_team_tags_options,
      procedure_stage_tags_options,
      // Select Api call Funtions
      fetchVacancyTypeData,
      fetchVerticalData,
      fetchPracticeAreaData,
      fetchSubPracticeAreaData,
      fetchFunctionData,
      fetchSubFunctionData,
      fetchSubSubFunctionData,
      fetchSectorData,
      fetchIndustryData,
      fetchCompanyTypeData,
      fetchTeamsData,
      fetchSubTeamsData,
      fetchProcedureStageData,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../styles/candidateGroupFilter.scss";
</style>
